

  import { Directive, ElementRef, HostListener } from '@angular/core';

  @Directive({
    selector: '[appPhoneMasking]'
  })
  export class PhoneMaskingDirective {
  
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event: any) {
      const initialValue = this.el.nativeElement.value;
      console.log("this.el.nativeElement",this.el.nativeElement,this.el,this.el.nativeElement.value)
      const tagInput = this.el.nativeElement.querySelector('input');
      if (tagInput) {
        const inputValue = tagInput.value;
        let formattedValue = inputValue.replace(/\D/g, '');
        if (formattedValue.length > 10) {
          formattedValue = formattedValue.slice(0, 10); // Limit to 10 digits
        }
        formattedValue = this.formatPhoneNumber(formattedValue);      
        tagInput.value = formattedValue;
        this.triggerInputEvent(tagInput); // Trigger input event manually
      }
      else{
        let formattedValue = initialValue.replace(/\D/g, '');
        if (formattedValue.length > 10) {
          formattedValue = formattedValue.slice(0, 10); // Limit to 10 digits
        }
        formattedValue = this.formatPhoneNumber(formattedValue);
        this.el.nativeElement.value = formattedValue;
      }
    }
    private triggerInputEvent(element: HTMLElement): void {
      const event = new Event('input', { bubbles: true });
      element.dispatchEvent(event);
    }
    private formatPhoneNumber(phoneNumber: string): string {
      const areaCode = phoneNumber.slice(0, 3);
      const firstPart = phoneNumber.slice(3, 6);
      const secondPart = phoneNumber.slice(6);
      if (phoneNumber.length <= 3) {
        return phoneNumber;
      } else if (phoneNumber.length <= 6) {
        return `(${areaCode}) ${firstPart}`;
      } else {
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
    }
  }