

export const environment = {
  production: false,
  
  apiUrls: {
    socketUrl:'http://18.224.174.17:1333',
    master: 'https://qcbackend.purpleinvestor.com/api',
    // master: 'https://purpleapi.wondrfly.com/api',
    wondrfly: 'https://wondrfly.com/api',
    imageUrl: 'https://wondrfly.s3.us-east-2.amazonaws.com/',
    userImageUrl: 'https://wondrfly.s3.us-east-2.amazonaws.com/',
    programPageUrl : 'https://qc.wondrfly.com/program/activity-name'

  }
};
